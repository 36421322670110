import React from "react";
import { Link as GatsbyLink } from "gatsby";
import {
	Knobs,
	Example,
	DocPageMeta,
	ContentSection,
	HeaderSection,
	Header,
	Code,
} from "../../../../../src/docs";
import { TextContainer } from "../../text/text-container/text-container";
import { Paragraph } from "../../text/paragraph/paragraph";
import { Ul } from "../../text/list/list";
import { BasePopover } from "./base-popover";

export const Meta: DocPageMeta = {
	category: "Overlay",
	title: "Base Popover",
	notepad: "https://hackmd.io/ZsjwXj38STuOLot4D738BA",
};

export default (): JSX.Element => (
	<>
		<HeaderSection title="Base Popover" subTitle="A wrapper for React Popper" />
		<ContentSection>
			<TextContainer article>
				<Header.H2>Intro</Header.H2>
				<Paragraph>
					This component is used as a wrapper for{" "}
					<a href="https://popper.js.org/react-popper/v2/" target="_blank" rel="noreferrer">
						React Popper
					</a>
					, which is the official React wrapper for{" "}
					<a href="https://popper.js.org/" target="_blank" rel="noreferrer">
						Popper
					</a>
					, a tooltip and popover positioning engine.
				</Paragraph>
				<Header.H2>Basic Usage</Header.H2>
				<Paragraph>
					To make the <Code>BasePopover</Code> work, you need to give it three things: a reference (
					<Code>ref</Code>) to the element where the popover should appear, which goes into the{" "}
					<Code>anchor</Code> prop (make sure this ref is set on the element itself), the content
					for the popover in the <Code>popover</Code> prop, and whether the popover should be
					visible or hidden in the <Code>showPopover</Code> prop
				</Paragraph>
				<Paragraph>
					<Code>BasePopover</Code> is being used for the following Fancy components:
				</Paragraph>
				<Ul
					items={[
						<>
							<GatsbyLink to="/lab/components/Forms and input/Auto Complete">
								Auto Complete
							</GatsbyLink>
						</>,
						<>
							<GatsbyLink to="/lab/components/Overlay/Popover">Popover</GatsbyLink>
						</>,
						<>
							<GatsbyLink to="/lab/components/Overlay/Tooltip">Tooltip</GatsbyLink>
						</>,
					]}
				/>
				<Example fn={BasicUsage} />
				<Header.H2>Placement</Header.H2>
				<Paragraph>
					You can set the preferred placement by using any of the following values for the{" "}
					<Code>placement</Code> prop:
				</Paragraph>
				<Paragraph>
					<Code>auto</Code> <Code>auto-start</Code> <Code>auto-end</Code> <Code>top</Code>{" "}
					<Code>bottom</Code> <Code>right</Code> <Code>left</Code> <Code>top-start</Code>{" "}
					<Code>top-end</Code> <Code>bottom-start</Code> <Code>bottom-end</Code>{" "}
					<Code>right-start</Code> <Code>right-end</Code> <Code>left-start</Code>{" "}
					<Code>left-end</Code>
				</Paragraph>
				<Example fn={PlacementUsage} />
				<Header.H2>Offset</Header.H2>
				<Paragraph>
					You can set the distance in pixels between the anchor and the content by using the{" "}
					<Code>offset</Code> prop.
				</Paragraph>
				<Example fn={OffsetUsage} />
				<Header.H2>Properties</Header.H2>
				<Knobs
					component={BasePopover}
					initialProps={{
						popover: "Popover element",
						anchor: (ref) => <span ref={ref}>Anchor element</span>,
						showPopover: false,
					}}
				/>
				<div style={{ height: 1000 }}>
					{/* I put this here so that you can scroll the popover out of view */}
				</div>
			</TextContainer>
		</ContentSection>
	</>
);

const BasicUsage = () => (
	<BasePopover
		popover={<div style={{ backgroundColor: "#eee", padding: 8 }}>popover</div>}
		anchor={(ref) => <span ref={ref}>anchor element</span>}
		showPopover={true}
	/>
);

const PlacementUsage = () => (
	<BasePopover
		popover={<div style={{ backgroundColor: "#eee", padding: 8 }}>popover</div>}
		anchor={(ref) => <span ref={ref}>anchor element</span>}
		showPopover={true}
		placement="left"
	/>
);

const OffsetUsage = () => (
	<BasePopover
		popover={<div style={{ backgroundColor: "#eee", padding: 8 }}>popover</div>}
		anchor={(ref) => <span ref={ref}>anchor element</span>}
		showPopover={true}
		offset={20}
	/>
);
